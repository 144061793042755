import Message from "./message";

/**
 * Class implementing the websocket broker
 */
export default class Websocket {
  /**
   * a collection of all messages flying on the websocket
   */
  messages: Array<Message>
  /**
   * the websocket connection object
   */
  connection: WebSocket | null
  /**
   * the websocket connection state
   */
  state: number
  /**
   * the url to establish the connection with
   */
  url: string
  constructor() {
    this.connection = null;
    this.messages = [];
    this.url = '';
    this.state = -1;
  }

  /**
   * Set the url to connect to
   * 
   * @param url the url of the websocket connection
   * @returns this class
   */
  setUrl(url: string): Websocket {
    this.url = url;
    return this;
  }

  /**
   * Opens a websocket connection and starts listening on the wire
   * for websocket events
   * 
   * @returns a promise with this class or an reject error
   */
  connect(): Promise<Websocket> {
    const _this = this;

    return new Promise((resolve, reject) => {
      try {
        _this.connection = new WebSocket(_this.url);
      } catch (err) {
        reject(err);
      }

      if (_this.connection) {
        _this.connection.onopen = () => {
          _this.state = 1;
          resolve(_this);
        }
  
        _this.connection.onmessage = (ev: any) => {
          _this.messages.unshift(new Message(Message.Direction.IN, ev.data));
        }
  
        _this.connection.onclose = () => {
          this.connection = null;
          this.state = 4;
          reject(null);
        }
  
        _this.connection.onerror = (err: any) => {
          reject(err);
        }
      }
    });
  }

  /**
   * Close the websocket connection
   */
  disconnect(): void {
    if (this.connection) {
      this.connection.close(1000, 'Bye');
    }
  }

  /**
   * Send a message down the wire
   * 
   * @param messageBody the body of the message to send via websocket
   */
  sendMessage(messageBody: string): void {
    if (this.connection) {
      this.connection.send(messageBody);
      this.messages.unshift(new Message(Message.Direction.OUT, messageBody));
    }
  }

  /**
   * clear the history of all the websocket messages
   */
  clearMessages(): void {
    this.messages = [];
  }

}